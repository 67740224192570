<template>
	<main id="paxExpress" class="pax_global">
		<!-- <header>
			<b-navbar toggleable="lg">
				<b-navbar-brand href="#"><groomy-logo/></b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"><font-awesome-icon :icon="['far', 'stream']" /> Menu</b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<b-nav-item href="https://www.gfeweb.com/?open_menu=true" target="_blank"><font-awesome-icon :icon="['far', 'stream']" />  {{ $t('pax.menu') }}</b-nav-item>
					</b-navbar-nav> -->
					<!-- Right aligned nav items -->
				<!-- </b-collapse>
			</b-navbar>
		</header> -->

		<div class="tabs mb-3 d-lg-none">
			<ul class="nav nav-tabs nav-justified">
				<li class="nav-item"><a class="nav-link" :class="{'active' : step < 3, }" @click="openMenu"><b><font-awesome-icon :icon="['fas', 'home']"/></b><span>{{ $t('pax.accueil') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 3,'active' : step === 3, }" @click="goToStep(3)"><b>1</b><span>{{ $t('pax.etalons') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 5, 'active': step === 5}" @click="goToStep(4)"><b>2</b><span>{{ $t('pax.juments') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 6, 'active': step === 6}" @click="goToStep(5)"><b>3</b><span>{{ $t('pax.comparaison') }}</span></a></li>
			</ul>
		</div>
		
		<div class="container-fluid">
			<div class="row justify-content-center">
				<ChoixLangue v-show="step === 1" @langue_choosed="goToCountry"/>
				<ChoixCountry v-show="step === 2" @country_choosed="goToPax"/>
				<template v-if="step > 2">
					<div class="col bstallion" v-show="step === 3 || ((isLg || isXl) && (step === 4 || step === 5))">
						<Stallions 
							ref="stallions" 
							:stallions_selected.sync="stallions_selected" 
							:step_label.sync="step_label" 
							:stallions_preselected="stallions_preselected"
							:filtre_categories.sync="filtre_categories"
							:filtre_prix.sync="filtre_prix"
						/>
					</div>
					<div class="col" v-show="step == 4">
						<Selection :force_refresh="force_refresh" :stallions.sync="stallions_selected" :step_label.sync="step_label"></Selection>
					</div>
					<div class="col" v-show="step == 5">
						<SelectionMare :force_refresh="force_refresh" :mare.sync="mare" :step_label.sync="step_label"></SelectionMare>
					</div>
				</template>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
    import Constants from 'Constants'
	import UserMixin from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'
	import PaxMixin from '@/mixins/Pax.js'
	import Config from '@/mixins/Config.js'
	import Window from '@/mixins/Window.js'
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: "Pax",
		mixins: [UserMixin, Navigation, PaxMixin, Config, Window],
		data () {
			return {
				stallions_selected: [],
				stallions_preselected: [],
				criteres: [],
				mare: {},
				displayCompatibilite: false,
				force_refresh: false,
				step: 1,
				step_label: "",
				steps: {'stallions_selected': 5, 'stallions': 3, 'mare_list': 5, 'country': 2},
				filtre_categories: [],
				filtre_prix: []
			}
        },
		created() {
            this.init_component()
		},
		methods: {
			async init_component() {
				document.querySelector('html').classList.remove('no_scroll')
				this.stallions_selected = []
				this.stallions_preselected = []
				this.displayCompatibilite = false
				this.criteres = []
				// au reload permet de mettre à jour les critères à l'étape pax
				this.force_refresh = _uniqueId()

				const lang = this.getConfig('lang')
				const country = this.getConfig('country')
				if(lang && country) {
					this.step = 3
				}
				else if(lang && !country) {
					this.step = 2
				}
				else {
					this.step = 1
				}

				if(this.$route.params.stallion_ids) {
					this.stallions_preselected = this.$route.params.stallion_ids.split(',').map(s => parseInt(s))
					this.step_label = 'stallions'
				}
			},

			async goToStep(step_to_go) {
				if(step_to_go < this.step) {
					this.step_label = Object.keys(this.steps).find(key => this.steps[key] === step_to_go)
				}
			},

			goToCountry() {
				this.step = 2
			},

			goToPax() {
				this.step = 3
			},

			goToHome() {
				this.init_component()
			},

			openMenu() {
				this.$store.commit('nav/open')
			}
		},
		computed: {
			lang() {
				return this.getConfig('lang') ?? false
			},
			country() {
				return this.getConfig('country') ?? false
			},
		},
		watch: {
			step_label(val) {
				if(val == "stallions_selected") {
					this.step = 5
				}
				else if(val == "stallions") {
					this.step = 3
				}
				else if(val == "mare_list") {
					this.step = 5
				}
				else if(val == "mare_choosed") {
					const ids = this.stallions_selected.map(stallion => stallion.horse_id)
					let params = { 
						stallion_ids : ids.join(','),
						horse_id: this.mare.horse_id,
						filtre_categories: this.filtre_categories.join(','), 
						filtre_prix: this.filtre_prix.join(',')
					}
					this.$router.push({ name: 'HorsePax', params })
				}
				else if(val == "country") {
					this.step = 2
				}
			}
		},
		components: {
			Stallions : () => import('@/components/Pax/Stallions'),
			Mare : () => import('@/components/Pax/Mare'),
			SelectionMare : () => import('@/components/Pax/SelectionMare'),
			Selection : () => import('@/components/Pax/Selection'),
			ResultCompatibiliteH : () => import('@/components/Pax/ResultCompatibiliteH'),
			ResultCompatibiliteV : () => import('@/components/Pax/ResultCompatibiliteV'),
			ChoixLangue: () => import('@/components/Pax/ChoixLangue'),
			ChoixCountry: () => import('@/components/Pax/ChoixCountry')
		}
	};
</script>
